package com.rtarita.skull.client.components

import dev.fritz2.core.RenderContext
import dev.fritz2.core.Store
import dev.fritz2.core.Tag
import dev.fritz2.core.storeOf
import kotlinx.coroutines.FlowPreview
import kotlinx.coroutines.flow.debounce
import kotlinx.coroutines.flow.map
import org.w3c.dom.HTMLDivElement

class SidebarComponent(
    id: String,
    val elementsCollapsed: Store<List<Fritz2Component<*>>>,
    val elementsExpanded: Store<List<Fritz2Component<*>>>
) : Fritz2Component<HTMLDivElement>(id) {
    companion object {
        private const val TRANSITION_DEBOUNCE_MILLIS = 200L
    }

    private val classes = listOf(
        "absolute",
        "transition-all",
        "delay-200",
        "hover:delay-0",
        "ease-in-out",
        "hover:w-[20rem]",
        "h-full",
        "w-16",
        "bg-blue-700",
        "m-0",
        "flex",
        "flex-col"
    )

    @OptIn(FlowPreview::class)
    override fun render(context: RenderContext): Tag<HTMLDivElement> = context.run {
        val hoverStore = storeOf(false)
        div {
            classList(classes)
            hoverStore.data
                .debounce(TRANSITION_DEBOUNCE_MILLIS)
                .render(into = this) { hovered ->
                    val componentStore = if (hovered) elementsExpanded else elementsCollapsed
                    componentStore.data.renderEach(into = this@div) { elem ->
                        elem.render(this)
                    }
                }
            mouseenters.map { true } handledBy hoverStore.update
            mouseleaves.map { false } handledBy hoverStore.update
        }
    }
}
