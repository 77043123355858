package com.rtarita.skull.client.components

import dev.fritz2.core.RenderContext
import dev.fritz2.core.Tag
import org.w3c.dom.HTMLElement

abstract class Fritz2Component<T : HTMLElement>(val id: String) {
    abstract fun render(context: RenderContext): Tag<T>
    override fun equals(other: Any?): Boolean = other is Fritz2Component<*> && other.id == id
    override fun hashCode(): Int = id.hashCode()
}
