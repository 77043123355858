package com.rtarita.skull.client.components

import dev.fritz2.core.RenderContext
import dev.fritz2.core.Tag
import org.w3c.dom.HTMLElement

fun <T : HTMLElement> component(
    id: String,
    renderFunction: RenderContext.() -> Tag<T>
): Fritz2Component<T> = object : Fritz2Component<T>(id) {
    override fun render(context: RenderContext): Tag<T> = context.renderFunction()
}
