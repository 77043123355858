package com.rtarita.skull.client

import com.rtarita.skull.client.components.Fritz2Component
import com.rtarita.skull.client.components.SidebarComponent
import com.rtarita.skull.client.components.component
import dev.fritz2.core.RenderContext
import dev.fritz2.core.Store
import dev.fritz2.core.`for`
import dev.fritz2.core.render
import dev.fritz2.core.storeOf
import dev.fritz2.core.type
import dev.fritz2.core.value
import dev.fritz2.core.values

fun main() {
    render {
        val elements = listOf("First Element", "Second Element", "Third Element")
        val elementsCollapsed: Store<List<Fritz2Component<*>>> = storeOf(elements.mapIndexed { idx, _ ->
            component("elem-$idx") {
                div("w-full h-16 p-6 text-white bg-transparent hover:bg-blue-800") {
                    +(idx + 1).toString()
                }
            }
        })
        val elementsExpanded: Store<List<Fritz2Component<*>>> = storeOf(elements.mapIndexed { idx, elem ->
            component("elem-$idx") {
                div("w-full h-16 p-6 text-white bg-transparent hover:bg-blue-800") {
                    +elem
                }
            }
        })

        div("h-screen w-screen", id = "screen") {
            div("h-full w-full flex flex-row items-center justify-between") {
                SidebarComponent("sidebar", elementsCollapsed, elementsExpanded).render(this)
                div(id = "start") {}
                loginScreen()
                div(id = "end") {}
            }
        }


    }
}

private fun RenderContext.loginScreen() {
    val userIdStore = storeOf("")
    val passwordStore = storeOf("")

    div("flex flex-col") {
        label {
            `for`(userIdStore.id)
            +"User-ID"
        }
        input {
            type("text")
            value(userIdStore.data)
            changes.values() handledBy userIdStore.update
        }

        label {
            `for`(passwordStore.id)
            +"Password"
        }
        input {
            type("text")
            value(passwordStore.data)
            changes.values() handledBy passwordStore.update
        }
    }
}
